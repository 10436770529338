import React, { useState, useEffect } from 'react'
import Layout from '../layouts/Layout'
import Form from '../components/Form'
import { LangContext } from '../utils/I18n'

const FormTemplate = data => {
  const [showBanner, setBanner] = useState(false)
  const { pageContext: { lang, formActive } } = data
  const date = new Date().getDate(); //To get the Current Date
  const month = new Date().getMonth() + 1; //To get the Current Month
  const year = new Date().getFullYear(); //To get the Current Year
  const hours = new Date().getHours(); //To get the Current Hours
  const min = new Date().getMinutes(); //To get the Current Minutes
  const startDateTime = '6-2-2025 19:00';
  const endDateTime = '6-2-2025 19:30';

  useEffect(() => {
    const currentDate = `${date}-${month}-${year} ${hours}:${min}`;
    console.log(currentDate)
    if (currentDate >= startDateTime && currentDate <= endDateTime) {
      console.log('SHOW Banner');
      setBanner(true);
    } else {
      console.log('Dont show banner');
      setBanner(false);
    }
  }, [])




  return (
    <LangContext.Provider value={lang}>
      <Layout>
        <div>
          {showBanner && (
            <div className="site-down-error">
              &nbsp;&nbsp;&nbsp;This page is currently down for maintenance. We expect to be back in a couple hours. Thanks for your patience.
            </div>
          )}
          <tm-page>
            <Form formActive={formActive} />
            <div>
              <th-element-loader element="tm-page" style={{ height: '100vh' }} />
            </div>
          </tm-page>
        </div>
      </Layout>
    </LangContext.Provider>
  )
}

export default FormTemplate
